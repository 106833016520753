import App from "./App.svelte";

const root = document.createElement("div");
root.id = "adbot-app";
document.body.appendChild(root);

window.adbotInit = (settings) =>
  new App({
    target: document.getElementById("adbot-app"),
    props: { settings: settings || {} },
  });
