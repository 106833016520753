<script>
  import { cx, css } from "emotion/dist/emotion.umd.min.js";

  export let settings;

  const { title, url, thumbnail, openAtStartup, headerColor } = settings;

  let isOpen = !!openAtStartup;
  let hasBeenOpened = !!openAtStartup;
</script>

<div
  class={css`
    all: revert;
    font-family: Helvetica, sans-serif;
  `}
>
  <div
    class={cx(css`
      display: flex;
      flex-direction: row;
      position: fixed;
      bottom: 8px;
      right: 8px;
    `)}
  >
    <div
      class={cx(
        css`
          position: relative;
          margin-left: 8px;
          width: 64px;
          height: 64px;
          border-radius: 64px;
          border: 1px solid #9e9e9e;
          background-image: url(${thumbnail});
          background-size: cover;
          background-position: center center;
          cursor: pointer;
          transition: filter 0.5s ease, bottom 0.5s ease;

          &:hover {
            filter: brightness(1.05);
          }
        `,
        isOpen && css``
      )}
      on:click={() => {
        isOpen = !isOpen;
        hasBeenOpened = true;
      }}
    >
      <div
        class={cx(
          css`
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            position: absolute;
            top: -5px;
            left: -3px;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background-color: #d70000;
            color: #fff;
            opacity: 1;
            transition: opacity 0.5s ease;
          `,
          isOpen &&
            css`
              opacity: 0;
            `
        )}
      >1</div>

      <div
        class={cx(
          css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 64px;
            border-radius: 64px;
            background-color: #888;
            opacity: 0;
            transition: opacity 0.5s ease;
          `,
          isOpen &&
            css`
              opacity: 1;
            `
        )}
      >
        <img
          src="https://i.imgur.com/n0THqvm.png"
          alt="Chiudi"
          class={cx(css`
            width: 32px;
            height: 32px;
          `)}
        />
      </div>
    </div>
  </div>

  <div
    class={cx(
      css`
        position: fixed;
        bottom: 66px;
        right: 8px;
        overflow: hidden;
        width: 0px;
        height: 0px;
        opacity: 0;
        border: 1px solid #c1c1c1;
        border-radius: 8px;
        transition: opacity 0.5s ease, bottom 0.5s ease, height 0.01s ease 0.5s,
          width 0.01s ease 0.5s;
      `,
      isOpen &&
        css`
          bottom: 86px;
          opacity: 1;
          width: 300px;
          height: 360px;

          @media (min-width: 1024px) {
            width: 390px;
            height: 480px;
          }

          transition: opacity 0.5s ease, bottom 0.5s ease;

          @media (max-width: 768px) {
            width: calc(100vw - 16px);
            height: calc(100vh - 215px);
          }
        `
    )}
  >
    <div
      class={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: ${headerColor || "#888"};
        font-weight: 600;
        height: 35px;
      `}
    >
      {title}

      <img
        src="https://i.imgur.com/n0THqvm.png"
        alt="Chiudi"
        on:click={() => (isOpen = false)}
        class={cx(css`
          position: absolute;
          right: 8px;
          height: 20px;
          width: 20px;
          cursor: pointer;
        `)}
      />
    </div>

    {#if hasBeenOpened}
      <iframe
        src={url}
        frameborder="0"
        title="Chat"
        class={css`
          width: 100%;
          height: calc(100% - 35px);
        `}
      />
    {/if}
  </div>
</div>
